<template>
    <v-card>
        <v-container>
            <form ref="comarcaForm">
                <v-form
                    ref="form"
                    v-model="valid"
                    @keyup.native.enter="salvarComarca"
                >
                    <v-row>
                        <v-col cols="12" sm="3" md="3" style="display: grid">
                            <v-icon
                                class="grey--text ma-3 pa-3 mx-auto my-auto"
                                :size="getIconSize()"
                                elevation-8
                            >
                                mdi-map-search-outline
                            </v-icon>
                            <v-card-text
                                class="
                                        grey--text
                                        font-weight-black
                                    "
                                style="font-size: 32px; display: flex; justify-content: center; align-items: center"
                            >
                                Detalhes
                                <br v-if="isSmall || isMedium" /><br
                                    v-if="isSmall || isMedium"
                                />da <br /><br />
                                Comarca
                            </v-card-text>
                        </v-col>
                        <v-col cols="12" sm="9" md="9">
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    :class="isExtraSmall ? 'px-3 py-0' : ''"
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                    >
                                        Código da Comarca
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="codComarca"
                                        label="Código"
                                        type="text"
                                        disabled
                                        solo
                                        v-model="comarca.cd_ibge_comarca"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="6"
                                    :class="isExtraSmall ? 'px-3 py-0' : ''"
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                    >
                                        UF da Comarca
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="CodUF"
                                        label="Código da UF"
                                        type="text"
                                        disabled
                                        solo
                                        v-model="comarca.cd_uf"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    :class="isExtraSmall ? 'px-3 py-0' : ''"
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                    >
                                        Nome da Comarca
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="NomeComarca"
                                        label="Nome da Comarca"
                                        type="text"
                                        disabled
                                        solo
                                        v-model="comarca.nm_comarca"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                    :class="isExtraSmall ? 'px-3 py-0' : ''"
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                    >
                                        Nome do Município
                                    </v-card-text>
                                    <v-text-field
                                        prepend-icon="mdi-sticker-text"
                                        name="NomeMunicipio"
                                        label="Nome do Município"
                                        type="text"
                                        disabled
                                        solo
                                        v-model="comarca.nm_municipio"
                                        required
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8" md="8">
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                        style="font-size: 24px"
                                    >
                                        Limite Diário de envios
                                        <v-tooltip
                                            bottom
                                            v-if="!isExtraSmall && !isSmall"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    color="grey lighten-2
     "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    help
                                                </v-icon>
                                            </template>
                                            <span
                                                >Informe no campo ao lado a
                                                quantidade envios que essa
                                                comarca irá realizar
                                                diariamente.</span
                                            >
                                        </v-tooltip>
                                    </v-card-text>
                                </v-col>
                                <v-col
                                    class="grid-center"
                                    cols="12"
                                    sm="4"
                                    md="4"
                                >
                                    <v-row>
                                        <div class="grid-center">
                                            <v-btn
                                                class="mx-2"
                                                fab
                                                dark
                                                x-small
                                                color="primary"
                                                @click="aumentarLimite"
                                            >
                                                <v-icon dark>
                                                    mdi-plus
                                                </v-icon>
                                            </v-btn>
                                            <v-btn
                                                class="mx-2 mt-1"
                                                fab
                                                dark
                                                x-small
                                                color="primary"
                                                @click="diminuirlimite"
                                            >
                                                <v-icon dark>
                                                    mdi-minus
                                                </v-icon>
                                            </v-btn>
                                        </div>
                                        <v-text-field
                                            name="limDiario"
                                            oninput="if(this.value < 0) this.value = 0;"
                                            hide-details
                                            height="72"
                                            filled
                                            class="
                                                        display-1
                                                        font-weight-light
                                                        pa-3
                                                        mx-auto
                                                        input-limite-diario
                                                    "
                                            solo
                                            v-model="comarca.ds_limite_diario"
                                            v-maska="'####'"
                                            required
                                            @focus="focusInput($event)"
                                        ></v-text-field>
                                    </v-row>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="8"
                                    md="8"
                                    :class="
                                        isExtraSmall ? 'px-3 pt-3 pb-0' : ''
                                    "
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                        style="font-size: 24px"
                                        >Status da Comarca
                                        <v-tooltip
                                            bottom
                                            v-if="!isExtraSmall && !isSmall"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    color="grey lighten-2
     "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    help
                                                </v-icon>
                                            </template>
                                            <span
                                                >Selecione no campo ao lado para
                                                definir se a comarca está ativa
                                                ou inativa</span
                                            >
                                        </v-tooltip>
                                    </v-card-text>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                    class="flex-start"
                                    :class="
                                        isExtraSmall ? 'px-3 pt-0 pb-3' : ''
                                    "
                                >
                                    <v-switch
                                        class="ma-3"
                                        color="success"
                                        v-model="comarca.st_ativo"
                                        inset
                                        hide-details
                                        label="Ativo/Inativo"
                                        data-cy="btnStatusComarca"
                                    ></v-switch>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="8"
                                    md="8"
                                    :class="
                                        isExtraSmall ? 'px-3 pt-3 pb-0' : ''
                                    "
                                >
                                    <v-card-text
                                        class="grey--text font-weight-bold"
                                        style="font-size: 24px"
                                        >Comarca requer Endosso
                                        <v-tooltip
                                            bottom
                                            v-if="!isExtraSmall && !isSmall"
                                        >
                                            <template
                                                v-slot:activator="{
                                                    on,
                                                    attrs
                                                }"
                                            >
                                                <v-icon
                                                    color="grey lighten-2
     "
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                                    help
                                                </v-icon>
                                            </template>
                                            <span
                                                >Selecione no campo ao lado para
                                                definir se a comarca requer endosso ou não</span
                                            >
                                        </v-tooltip>
                                    </v-card-text>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                    class="flex-start"
                                    :class="
                                        isExtraSmall ? 'px-3 pt-0 pb-3' : ''
                                    "
                                >
                                    <v-switch
                                        class="ma-3"
                                        color="success"
                                        v-model="comarca.requer_endosso"
                                        inset
                                        hide-details
                                        :label="comarca.requer_endosso ? 'Sim' : 'Não'"
                                    ></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </form>
        </v-container>
    </v-card>
</template>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>

<style>
.input-limite-diario {
    width: 125px;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.grid-center {
    display: grid;
    justify-content: center;
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<script>
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'DetalheComarca',
    props: {
        comarca: {
            type: Object,
            required: false
        },
        showActionSalvar: Boolean
    },
    data() {
        return {
            isExtraSmall: false,
            isSmall: false,
            isMedium: false,
            show1: false,
            errorMessages: null,
            items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
            id_user: '',
            ds_login: '',
            st_ativo: false,
            enabled: false,
            password: '',
            email: '',
            valid: null,
            createPassword: '',
            emailConfirmation: '',
            passwordRules: [
                v => !!v || 'Senha é requerida',
                v => v.length >= 2 || 'A senha deve ter 2 ou mais caracteres'
            ],
            passwordRulesConfirmation: [
                v => !!v || 'Senha é requerida',
                v => v == this.comarca.password || 'As senhas devem ser iguais'
            ],
            emailRulesConfirmation: [
                v => !!v || 'Email é requerido',
                v => v.length >= 2 || 'O e-mail deve ter 2 ou mais caracteres'
            ],
            nameRulesConfirmation: [
                v => !!v || 'Nome é requerido',
                v => v.length >= 2 || 'O nome deve ter 2 ou mais caracteres'
            ]
        };
    },
    methods: {
        focusInput($e) {
            $e.target.select();
        },
        aumentarLimite() {
            if (Number(this.comarca.ds_limite_diario) < 9999) {
                const soma = Number(this.comarca.ds_limite_diario) + 1;
                this.comarca.ds_limite_diario = String(soma);
            } else {
                this.comarca.ds_limite_diario = '9999';
            }
        },
        diminuirlimite() {
            let subtracao = Number(this.comarca.ds_limite_diario) - 1;
            if (subtracao < 0) {
                subtracao = 0;
            }
            this.comarca.ds_limite_diario = String(subtracao);
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        handleBreakpoints(val) {
            this.isExtraSmall = false;
            this.isSmall = false;
            this.isMedium = false;
            if (val.xs) {
                this.isExtraSmall = true;
            } else if (val.sm) {
                this.isSmall = true;
            } else if (val.md) {
                this.isMedium = true;
            }
        },
        getIconSize() {
            if (this.isExtraSmall) {
                return '60';
            } else if (this.isSmall) {
                return '100';
            } else if (this.isMedium) {
                return '150';
            } else {
                return '150';
            }
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                this.$emit('fecharModal');
            }
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.handleBreakpoints(val);
            }
        }
    },
    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint;
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
        this.handleBreakpoints(this.breakpoint);
    }
};
</script>
