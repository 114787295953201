<template>
    <div align="center" justify="center" class="pa-3">
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2">
                    mdi-map-marker-circle</v-icon
                >Comarcas > Listar Comarcas
            </v-card-text>
        </v-card>
        <!-- Cabeçalho -->
        <div align="center" justify="center">
            <v-card @keyup.native.enter="botaoListarComarcas" class="pa-5 my-3">
                <v-row>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                        class="alinhar-filtros"
                        :class="isExtraSmall || isSmall ? 'pb-0' : ''"
                    >
                        <v-text-field
                            solo
                            :prepend-icon="
                                !isExtraSmall && !isSmall
                                    ? 'mdi-chevron-right'
                                    : ''
                            "
                            small
                            label="Código da Comarca"
                            hint="Informe o Código da Comarca que deseja buscar"
                            placeholder="Ex.:0000349"
                            v-model="filtros.cd_ibge_comarca"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="6"
                        lg="4"
                        class="alinhar-filtros"
                        :class="isExtraSmall || isSmall ? 'py-0' : ''"
                    >
                        <v-text-field
                            solo
                            small
                            label="Nome do Município"
                            hint="Informe o nome do múnicípio que deseja buscar"
                            placeholder="Ex.: Belo Horizonte"
                            clearable
                            v-model="filtros.nm_municipio"
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="2"
                        lg="2"
                        :class="isExtraSmall || isSmall ? 'py-0' : ''"
                    >
                        <v-select
                            solo
                            small
                            v-model="filtros.cd_uf"
                            :items="listaUfs"
                            label="UF"
                            :return-object="true"
                        ></v-select>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="4"
                        lg="3"
                        class="alinhar-filtros"
                        :class="isExtraSmall || isSmall ? 'py-0' : ''"
                    >
                        <v-text-field
                            solo
                            :prepend-icon="isMedium ? 'mdi-chevron-right' : ''"
                            small
                            label="CEP"
                            hint="Informe o CEP da Comarca que deseja pesquisar"
                            placeholder="Ex.: 00000-000"
                            v-model="filtros.cep_comarca"
                            v-maska="'#####-###'"
                            clearable
                        ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        class="px-3"
                        :class="
                            isExtraSmall
                                ? 'text-center pt-0'
                                : isSmall
                                ? 'text-right pt-0'
                                : 'text-right pt-3'
                        "
                    >
                        <v-btn
                            color="green white--text"
                            @click="botaoListarComarcas"
                            data-cy="buscarComarcasBtn"
                        >
                            Buscar Comarcas
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemRetorno"
                @fecharSnackbar="snackbar = false"
            />            
            <v-data-table
                data-cy="dataTableComarcas"
                :loading="loading"
                no-data-text="Não há comarcas listadas"
                loading-text="Carregando Comarcas..."
                :headers="headers"
                :items="listaComarcas"
                item-key="comarcas"
                class="elevation-1 pa-3"
                :class="isExtraSmall ? 'alinhar-botao-editar' : ''"
                :footer-props="{
                    itemsPerPageOptions: [10, 20, 50, -1],
                    itemsPerPageText: 'Comarcas por página:'
                }"
            >
                <template v-slot:[`item.st_ativo`]="{ item }">
                    <v-switch
                        :color="item.st_ativo != '' ? 'green' : 'red'"
                        v-model="item.st_ativo"
                        inset
                        @change="alterarStatus(item)"
                        :style="{
                            color: item.st_ativo != '' ? 'green' : 'red'
                        }"
                    >
                    </v-switch>
                </template>
                <template v-slot:[`item.is_admin`]="{ item }">
                    <v-icon
                        :style="{
                            color: item.is_admin != '' ? 'green' : 'red'
                        }"
                        >{{ getBolean(item.is_admin) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span v-if="item.status == 'true'"><p>Ativo</p> </span>
                    <span v-if="item.cd_status == 'false'"
                        ><p>Desabilitado</p>
                    </span>
                </template>
                <template slot="item.action" slot-scope="props">
                    <v-btn
                        elevation="0"
                        width="90%"
                        :loading="dialogLoading"
                        @click="exibirDetalhe(props.item)"
                        :class="!isExtraSmall ? 'mr-2' : 'mb-2 mr-3'"
                        class="grey white--text"
                        :data-cy="`editarComarca${props.index}`"
                    >
                        <v-icon
                            medium
                            class="mr-2"
                            @click="exibirDetalhe(props.item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >Editar
                    </v-btn>
                </template>
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <!-- Tela para editar comarca -->
        <v-dialog
            v-if="dialogEditar"
            v-model="dialogEditar"
            max-width="1000px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn icon dark @click="closeDialogComarca">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Editar Comarca</v-toolbar-title>
                    </v-toolbar></v-card-title
                >
                <v-divider></v-divider>
                <v-card-text class="px-8 py-4">
                    <EditarComarca
                        :comarca="comarca"
                        @fecharModal="closeDialogComarca"
                    />
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions
                    :style="
                        !isExtraSmall
                            ? 'display: flex; justify-content: end'
                            : 'display: flex; justify-content: center'
                    "
                >
                    <v-btn
                        :class="!isExtraSmall ? 'ml-5' : ''"
                        color="green white--text"
                        data-cy="salvarComarca"
                        :width="isExtraSmall ? '100' : '120'"
                        @click="botaoSalvarComarca"
                    >
                        Salvar
                    </v-btn>
                    <v-btn
                        class="ml-5"
                        dense
                        color="error"
                        :width="isExtraSmall ? '100' : '120'"
                        @click="closeDialogComarca"
                    >
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<style>
.alinhar-filtros {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
</style>

<style scoped>
::v-deep .alinhar-botao-editar td:last-child {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
</style>

<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import ComarcaService from '@/services/ComarcaService';
import Comarca from '@/domains/Comarcas';
import EditarComarca from '@/components/EditarComarca';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import { getOnlyNumbers } from '@/helpers/Utilitarios';
import lodash from 'lodash';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'ListaComarcas',
    mixins: [validationMixin],
    components: {
        EditarComarca
    },

    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            dialogLoading: false,
            loading: false,
            isExtraSmall: false,
            isSmall: false,
            isMedium: false,
            switch1: true,
            switch0: false,
            listaComarcas: [],
            filtros: {
                cd_ibge_comarca: null,
                cd_uf: null,
                cd_limite_diario: null,
                nm_municipio: null,
                cep_comarca: null
            },
            listaUfs: [
                '',
                'AC',
                'AL',
                'AP',
                'AM',
                'BA',
                'CE',
                'DF',
                'ES',
                'GO',
                'MA',
                'MT',
                'MS',
                'MG',
                'PA',
                'PB',
                'PR',
                'PE',
                'PI',
                'RJ',
                'RN',
                'RS',
                'RO',
                'RR',
                'SC',
                'SP',
                'SE',
                'TO'
            ],
            dialog: false,
            dialogEditar: false,
            loadingFiltros: null,
            data_inicio: null,
            data_fim: null,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            showPagination: false,
            comarca: [],
            snackbar: false,
            mensagemRetorno: null,
            snackbarColor: 'green white--text',
            mensagem: null,
            headers: [
                {
                    text: 'UF',
                    sortable: false,
                    value: 'cd_uf'
                },
                {
                    text: 'Código da Comarca',
                    sortable: false,
                    value: 'cd_ibge_comarca'
                },
                {
                    text: 'Nome da Comarca',
                    sortable: false,
                    value: 'nm_comarca'
                },
                {
                    text: 'Limite Diário',
                    sortable: false,
                    value: 'ds_limite_diario'
                },
                {
                    text: 'CD Município',
                    sortable: false,
                    value: 'cd_ibge_municipio'
                },
                {
                    text: 'Nome do Municipio',
                    sortable: false,
                    value: 'nm_municipio'
                },
                {
                    text: 'CEP Inicial',
                    sortable: false,
                    value: 'cd_cep_abrangente_inicio'
                },
                {
                    text: 'CEP Final',
                    sortable: false,
                    value: 'cd_cep_abrangente_fim'
                },
                {
                    text: 'Status',
                    sortable: false,
                    value: 'st_ativo'
                },
                { text: ' ', sortable: false, value: 'action' }
            ]
        };
    },

    methods: {
        getOnlyNumbers,
        botaoListarComarcas() {
            const filtros = lodash.cloneDeep(this.filtros);
            filtros.cep_comarca = getOnlyNumbers(filtros.cep_comarca);
            if (
                !filtros.cep_comarca ||
                (filtros.cep_comarca && filtros.cep_comarca.length === 8)
            ) {
                this.loading = true;
                this.listaComarcas = [];
                return new Promise((resolve, reject) => {
                    try {
                        let comarcaService = new ComarcaService(
                            Vue.http,
                            this.$store
                        );
                        comarcaService.buscarComarcas(filtros).then(
                            response => {
                                if (response.status == 200) {
                                    this.listaComarcas = response.body;
                                    this.loading = false;
                                }
                            },
                            error => {
                                console.log(error);
                            }
                        );
                    } catch (e) {
                        this.mensagem = e;
                        console.log(e);
                        reject();
                    }
                });
            } else {
                this.mensagemRetorno = 'Digite um CEP válido.';
                this.snackbarColor = 'red white--text';
                this.snackbar = true;
            }
        },
        exibirDetalhe(comarca) {
            this.comarca = new Comarca();
            this.dialogLoading = true;
            let comarcaService = new ComarcaService(Vue.http, this.$store);
            comarcaService.editarComarcas(comarca).then(
                response => {
                    this.comarca = response.body[0];
                    this.dialogEditar = true;
                    this.dialogLoading = false;
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        alterarStatus(comarca) {
            this.comarca = comarca;
            this.botaoSalvarComarca();
        },
        botaoSalvarComarca() {
            let comarca = this.comarca;
            let comarcaService = new ComarcaService(Vue.http, this.$store);
            comarcaService.salvarComarcas(comarca).then(
                response => {
                    if (response.status == 200) {
                        this.mensagemRetorno = 'Comarca alterada com sucesso';
                        this.mensagem = response.body;
                        this.snackbarColor = 'green white--text';
                        this.snackbar = true;
                        this.dialogEditar = false;
                        this.dialogLoading = false;
                        this.botaoListarComarcas();
                    } else {
                        this.mensagemRetorno =
                            'Erro: entre em contato com a Recuperi';
                        this.snackbarColor = 'red white--text';
                        this.snackbar = true;
                        this.dialogLoading = false;
                    }
                },
                error => {
                    this.mensagem = error;
                    this.dialogLoading = false;
                    console.log(error);
                }
            );
        },
        closeDialogComarca() {
            this.dialogEditar = false;
        }
    },
    watch: {
        breakpoint: {
            deep: true,
            handler(val) {
                this.isExtraSmall = false;
                this.isSmall = false;
                this.isMedium = false;
                if (val.xs) {
                    this.isExtraSmall = true;
                } else if (val.sm) {
                    this.isSmall = true;
                } else if (val.md) {
                    this.isMedium = true;
                }
            }
        }
    },
    computed: {
        breakpoint() {
            return this.$vuetify.breakpoint;
        }
    }
};
</script>
