var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pa-3",attrs:{"align":"center","justify":"center"}},[_c('v-card',{attrs:{"align":"left","justify":"left"}},[_c('v-card-text',{staticClass:"pa-3 font-weight-light white black--text"},[_c('v-icon',{staticClass:"primary--text lighten-2 pa-2"},[_vm._v(" mdi-map-marker-circle")]),_vm._v("Comarcas > Listar Comarcas ")],1)],1),_c('div',{attrs:{"align":"center","justify":"center"}},[_c('v-card',{staticClass:"pa-5 my-3",nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.botaoListarComarcas.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{staticClass:"alinhar-filtros",class:_vm.isExtraSmall || _vm.isSmall ? 'pb-0' : '',attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-text-field',{attrs:{"solo":"","prepend-icon":!_vm.isExtraSmall && !_vm.isSmall
                                ? 'mdi-chevron-right'
                                : '',"small":"","label":"Código da Comarca","hint":"Informe o Código da Comarca que deseja buscar","placeholder":"Ex.:0000349","clearable":""},model:{value:(_vm.filtros.cd_ibge_comarca),callback:function ($$v) {_vm.$set(_vm.filtros, "cd_ibge_comarca", $$v)},expression:"filtros.cd_ibge_comarca"}})],1),_c('v-col',{staticClass:"alinhar-filtros",class:_vm.isExtraSmall || _vm.isSmall ? 'py-0' : '',attrs:{"cols":"12","sm":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"solo":"","small":"","label":"Nome do Município","hint":"Informe o nome do múnicípio que deseja buscar","placeholder":"Ex.: Belo Horizonte","clearable":""},model:{value:(_vm.filtros.nm_municipio),callback:function ($$v) {_vm.$set(_vm.filtros, "nm_municipio", $$v)},expression:"filtros.nm_municipio"}})],1),_c('v-col',{class:_vm.isExtraSmall || _vm.isSmall ? 'py-0' : '',attrs:{"cols":"12","sm":"12","md":"2","lg":"2"}},[_c('v-select',{attrs:{"solo":"","small":"","items":_vm.listaUfs,"label":"UF","return-object":true},model:{value:(_vm.filtros.cd_uf),callback:function ($$v) {_vm.$set(_vm.filtros, "cd_uf", $$v)},expression:"filtros.cd_uf"}})],1),_c('v-col',{staticClass:"alinhar-filtros",class:_vm.isExtraSmall || _vm.isSmall ? 'py-0' : '',attrs:{"cols":"12","sm":"12","md":"4","lg":"3"}},[_c('v-text-field',{directives:[{name:"maska",rawName:"v-maska",value:('#####-###'),expression:"'#####-###'"}],attrs:{"solo":"","prepend-icon":_vm.isMedium ? 'mdi-chevron-right' : '',"small":"","label":"CEP","hint":"Informe o CEP da Comarca que deseja pesquisar","placeholder":"Ex.: 00000-000","clearable":""},model:{value:(_vm.filtros.cep_comarca),callback:function ($$v) {_vm.$set(_vm.filtros, "cep_comarca", $$v)},expression:"filtros.cep_comarca"}})],1),_c('v-col',{staticClass:"px-3",class:_vm.isExtraSmall
                            ? 'text-center pt-0'
                            : _vm.isSmall
                            ? 'text-right pt-0'
                            : 'text-right pt-3',attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"12"}},[_c('v-btn',{attrs:{"color":"green white--text","data-cy":"buscarComarcasBtn"},on:{"click":_vm.botaoListarComarcas}},[_vm._v(" Buscar Comarcas "),_c('v-icon',{staticClass:"ml-2"},[_vm._v("search")])],1)],1)],1)],1),(_vm.snackbar)?_c('Snackbar',{attrs:{"mostrarSnackbar":_vm.snackbar,"corSnackbar":_vm.snackbarColor,"mensagemSnackbar":_vm.mensagemRetorno},on:{"fecharSnackbar":function($event){_vm.snackbar = false}}}):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 pa-3",class:_vm.isExtraSmall ? 'alinhar-botao-editar' : '',attrs:{"data-cy":"dataTableComarcas","loading":_vm.loading,"no-data-text":"Não há comarcas listadas","loading-text":"Carregando Comarcas...","headers":_vm.headers,"items":_vm.listaComarcas,"item-key":"comarcas","footer-props":{
                itemsPerPageOptions: [10, 20, 50, -1],
                itemsPerPageText: 'Comarcas por página:'
            }},scopedSlots:_vm._u([{key:`item.st_ativo`,fn:function({ item }){return [_c('v-switch',{style:({
                        color: item.st_ativo != '' ? 'green' : 'red'
                    }),attrs:{"color":item.st_ativo != '' ? 'green' : 'red',"inset":""},on:{"change":function($event){return _vm.alterarStatus(item)}},model:{value:(item.st_ativo),callback:function ($$v) {_vm.$set(item, "st_ativo", $$v)},expression:"item.st_ativo"}})]}},{key:`item.is_admin`,fn:function({ item }){return [_c('v-icon',{style:({
                        color: item.is_admin != '' ? 'green' : 'red'
                    })},[_vm._v(_vm._s(_vm.getBolean(item.is_admin)))])]}},{key:`item.status`,fn:function({ item }){return [(item.status == 'true')?_c('span',[_c('p',[_vm._v("Ativo")])]):_vm._e(),(item.cd_status == 'false')?_c('span',[_c('p',[_vm._v("Desabilitado")])]):_vm._e()]}},{key:"item.action",fn:function(props){return [_c('v-btn',{staticClass:"grey white--text",class:!_vm.isExtraSmall ? 'mr-2' : 'mb-2 mr-3',attrs:{"elevation":"0","width":"90%","loading":_vm.dialogLoading,"data-cy":`editarComarca${props.index}`},on:{"click":function($event){return _vm.exibirDetalhe(props.item)}}},[_c('v-icon',{staticClass:"mr-2",style:({ color: 'white' }),attrs:{"medium":""},on:{"click":function($event){return _vm.exibirDetalhe(props.item)}}},[_vm._v("edit")]),_vm._v("Editar ")],1)]}}],null,true)})],1),(_vm.dialogEditar)?_c('v-dialog',{attrs:{"max-width":"1000px","persistent":"","scrollable":"","color":"background","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogEditar),callback:function ($$v) {_vm.dialogEditar=$$v},expression:"dialogEditar"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"flat":"","dark":"","color":"primary"}},[_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":_vm.closeDialogComarca}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-toolbar-title',[_vm._v("Editar Comarca")])],1)],1),_c('v-divider'),_c('v-card-text',{staticClass:"px-8 py-4"},[_c('EditarComarca',{attrs:{"comarca":_vm.comarca},on:{"fecharModal":_vm.closeDialogComarca}})],1),_c('v-divider'),_c('v-card-actions',{style:(!_vm.isExtraSmall
                        ? 'display: flex; justify-content: end'
                        : 'display: flex; justify-content: center')},[_c('v-btn',{class:!_vm.isExtraSmall ? 'ml-5' : '',attrs:{"color":"green white--text","data-cy":"salvarComarca","width":_vm.isExtraSmall ? '100' : '120'},on:{"click":_vm.botaoSalvarComarca}},[_vm._v(" Salvar ")]),_c('v-btn',{staticClass:"ml-5",attrs:{"dense":"","color":"error","width":_vm.isExtraSmall ? '100' : '120'},on:{"click":_vm.closeDialogComarca}},[_vm._v(" Cancelar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }